import React, { createContext, useEffect, useState } from 'react';
import { Analytics } from 'services/analytics';
import { ExperimentProviderStateType } from './ExperimentProviderTypes';
import { useRouter } from 'next/router';
import jsCookie from 'js-cookie';
import { WEB_FUNNEL_META_NON_US_CAMPAIGN_ID } from 'utils/constants';

export const ExperimentContext = createContext({
  variants: {},
} as ExperimentProviderStateType);

ExperimentContext.displayName = 'ExperimentContext';

interface ExperimentProviderProps {
  children: React.ReactNode;
}

const ExperimentProvider: React.FC<ExperimentProviderProps> = ({
  children,
}) => {
  const [variants, setVariants] = useState<Record<string, string>>({});
  const router = useRouter();
  const shouldEngageInExperiments =
    (router?.query['utm_campaign'] ?? jsCookie.get('utm_campaign')) ===
      WEB_FUNNEL_META_NON_US_CAMPAIGN_ID || !!jsCookie.get('test_mode');

  useEffect(() => {
    if (!shouldEngageInExperiments) return;
    const asyncFetchExperiments = async () => {
      Analytics.initAmplitude();
      return Analytics.initializeExperiments();
    };
    asyncFetchExperiments()
      .then(response => {
        if (response) {
          const testModeValue = jsCookie.get('test_mode');
          if (!!testModeValue) {
            setVariants(
              Object.entries(response).reduce((acc, [key, _value]) => {
                acc[key] = testModeValue;
                return acc;
              }, {} as Record<string, string>),
            );
          } else {
            setVariants(response);
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [shouldEngageInExperiments]);

  return (
    <ExperimentContext.Provider value={{ variants }}>
      {children}
    </ExperimentContext.Provider>
  );
};

export default ExperimentProvider;
