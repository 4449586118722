import { useIsMobileView } from 'deepstash-ui';
import useLocation from 'hooks/useLocation';
import { useRouter } from 'next/router';
import React from 'react';
import { GENERIC_TAG_PATHNAME_PAGE } from 'utils/constants';
import SIMobileBlocked from './SIMobileBlocked';

const SIMobileWebChecker: React.FC = ({ children }) => {
  const isMobileView = useIsMobileView();

  const router = useRouter();
  const location = useLocation();
  // A mobile is allowed to be on some SI pages, like the share or invite
  const isOnAllowedPage =
    router.pathname === GENERIC_TAG_PATHNAME_PAGE ||
    location === 'ref' ||
    location === 'share' ||
    location === 'email_unsubscribe' ||
    location === 'terms-of-service' ||
    location === 'privacy-policy' ||
    location === 'producthunt' ||
    location === 'get-pro' ||
    location === 'get-lifetime' ||
    location === 'redeem' ||
    location === 'blackfriday22' ||
    location === 'xmas22' ||
    location === 'xmas' ||
    location === 'quiz' ||
    location === 'special-offer' ||
    location === 'secret-offer' ||
    location === 'daily-offer' ||
    location === 'resurrect-offer' ||
    location === 'yearly_promo' ||
    location === 'valentines' ||
    location === 'purchase' ||
    location === 'setup' ||
    location === 'halloween' ||
    location === 'welcome-discount' ||
    location === 'starter-discount' ||
    location === 'final-discount' ||
    location === 'holiday-special' ||
    location === 'new-year-offer' ||
    location === 'nesslabs' ||
    location === 'blackfriday23' ||
    location === 'easter' ||
    location === 'mothers-day' ||
    location === 'fathers-day' ||
    location === 'autumn-special' ||
    location === 'independence-day' ||
    location === 'growth-plan' ||
    location === 'activate-account';

  return isMobileView && !isOnAllowedPage ? (
    <SIMobileBlocked />
  ) : (
    <>{children}</>
  );
};

export default SIMobileWebChecker;
